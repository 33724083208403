import queryClient from '@api/queryClient'
import { Analytics } from '@vercel/analytics/react'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { StickyContainer } from 'react-sticky'
import 'rsuite/dist/rsuite.min.css'
import '../styles/global.css'

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StickyContainer>
        <Component {...pageProps} />
      </StickyContainer>

      <Toaster />
      <Analytics />
    </QueryClientProvider>
  )
}

export default appWithTranslation(MyApp)
